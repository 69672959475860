import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { commonUiLazyImports } from '@innogy/common-ui/lazy';
import {
  ConfigModule,
  VERTICAL_SCROLL_OFFSET,
} from '@innogy/core-config-angular';
import { CorePlatformModule } from '@innogy/core-platform';
import { SettingsTempModule } from '@innogy/core-settings';
import { CoreTranslateModule } from '@innogy/core-translate';
import {
  CoreAnalyticsModule,
  NotFoundAnalyticsEnhancer,
  TrackImpressionEnhancer,
} from '@innogy/core/analytics';
import { CoreConsentModule } from '@innogy/core/consent';
import { CoreExperienceEditorUtilsModule } from '@innogy/core/experience-editor-utils';
import { InitializeModule } from '@innogy/core/initialize';
import { CoreLoadFontsModule } from '@innogy/core/load-fonts';
import { CoreLoaderModule } from '@innogy/core/loader';
import { COMMON_JSS_IMPORTS } from '@innogy/core/modules/common';
import { CoreRoutingModule } from '@innogy/core/routing';
import { eplusComponentsLazyImports } from '@innogy/eplus-partners/components';
import { eplusCustomerSupportLazyImports } from '@innogy/eplus-partners/customer-support-form';
import { eplusEnergiewonenApointmentConfirmationLazyImports } from '@innogy/eplus-partners/energiewonen/appointment-confirmation';
import { EplusEnergiewonenCustomerSupportFormModule } from '@innogy/eplus-partners/energiewonen/customer-support-form';
import { eplusEnergiewonenFeedbackLazyImports } from '@innogy/eplus-partners/energiewonen/feedback';
import { eplusEnergiewonenIntakeLazyImports } from '@innogy/eplus-partners/energiewonen/intake';
import {
  EplusEnergiewonenPaymentModule,
  eplusEnergiewonenPaymentLazyImports,
} from '@innogy/eplus-partners/energiewonen/payment';
import { eplusEnergiewonenProjectOverviewLazyImports } from '@innogy/eplus-partners/energiewonen/project-overview';
import { eplusFaqLazyImports } from '@innogy/eplus-partners/faq';
import { eplusGridLazyImports } from '@innogy/eplus-partners/grid';
import { EplusHeaderModule } from '@innogy/eplus-partners/header';
import {
  EplusKnowledgeBaseModule,
  eplusKnowledgeBaseLazyImports,
} from '@innogy/eplus-partners/knowledge-base';
import { eplusNotificationBannerLazyImports } from '@innogy/eplus-partners/notification-banner';
import { EnergiewonenReviewsModule } from '@innogy/eplus-partners/reviews/store';
import { EplusUiOnlyModule } from '@innogy/eplus-partners/ui-only';
import { EplusIconModule } from '@innogy/icons';
import { CoreJssRoutingModule } from '@innogy/core-jss-routing';
import { NavigationEnergiewonenModule } from '@innogy/navigation';
import { UtilsModule } from '@innogy/utils-deprecated';
import {
  extModules,
  metaReducers,
  reducers,
  runtimeChecks,
} from '@innogy/utils-state';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';

import { EnergiewonenAppComponent } from './app.component';
import { EnergiewonenSharedModule } from './shared.module';
import { CoreJssSeoModule } from '@innogy/core-jss-seo';

export const VERTICAL_SCROLL_OFFSET_PROVIDER = {
  provide: VERTICAL_SCROLL_OFFSET,
  // This offset is used by <wl-setup-anchor-scrolling>
  useValue: 80,
};

export const sharedImports = [
  BrowserModule.withServerTransition({ appId: 'innogy-app' }),
  CoreAnalyticsModule.forRoot([
    NotFoundAnalyticsEnhancer,
    TrackImpressionEnhancer,
  ]),
  EffectsModule.forRoot([]),
  StoreModule.forRoot(reducers, {
    metaReducers,
    runtimeChecks,
  }),
  StoreRouterConnectingModule.forRoot({
    stateKey: 'router',
  }),
  HttpClientModule,
  CoreRoutingModule,
  CorePlatformModule,
  CoreJssSeoModule,
  CoreExperienceEditorUtilsModule,
  ConfigModule,
  SettingsTempModule,
  UtilsModule.forRoot({
    brandColor: '#8abf00',
    brandColorDark: '#8abf00',
  }),
  CoreLoadFontsModule,
  CoreAnalyticsModule,
  CoreJssRoutingModule,
  CoreTranslateModule.forRoot(),
  InitializeModule,
  TranslateModule,
  EplusHeaderModule,
  EplusKnowledgeBaseModule,
  EnergiewonenSharedModule,
  NavigationEnergiewonenModule,
  JssModule.withComponents(
    [...COMMON_JSS_IMPORTS],
    [
      ...commonUiLazyImports,
      ...eplusGridLazyImports,
      ...eplusFaqLazyImports,
      ...eplusNotificationBannerLazyImports,
      ...eplusKnowledgeBaseLazyImports,
      ...eplusComponentsLazyImports,
      ...eplusEnergiewonenFeedbackLazyImports,
      ...eplusEnergiewonenProjectOverviewLazyImports,
      ...eplusEnergiewonenApointmentConfirmationLazyImports,
      ...eplusEnergiewonenPaymentLazyImports,
      ...eplusEnergiewonenIntakeLazyImports,
      ...eplusCustomerSupportLazyImports,
    ]
  ),
  EplusIconModule,
  CoreConsentModule,
];

@NgModule({
  declarations: [EnergiewonenAppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    CoreLoaderModule.withConfig({
      spinner: false,
      color: '#8abf00',
    }),
    EplusUiOnlyModule,
    EnergiewonenReviewsModule,
    EplusEnergiewonenCustomerSupportFormModule,
    EplusEnergiewonenPaymentModule,
    ...sharedImports,
    ...extModules,
  ],
  bootstrap: [EnergiewonenAppComponent],
  providers: [
    VERTICAL_SCROLL_OFFSET_PROVIDER,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
  ],
})
export class EnergiewonenAppModule {}
