import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { GenericProductData } from '@innogy/common-ui/models';
import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import { TextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-generic-offer',
  templateUrl: './generic-offer.component.html',
  styleUrls: [
    './generic-offer.component.essent.scss',
    './generic-offer.component.ed.scss',
  ],
})
export class GenericOfferComponent<TProduct extends GenericProductData> {
  @Input() rendering: InnogyComponentRendering | any;
  @Input({ required: true }) product!: TProduct;
  @Input() buttonTextField?: TextField;
  @Input() mode: 'display' | 'select' = 'display';
  @Input() selected = false;

  @Output() clicked = new EventEmitter<TProduct>();
  @Output() secondaryClicked = new EventEmitter<TProduct>();

  get isSelectable() {
    return this.mode === 'select';
  }

  get isHighlighted() {
    return !!this.product.content.highlight;
  }

  get buttonLinkUrl() {
    return this.product.content.submitButtonAction === 'navigate'
      ? this.product.content.submitButtonLink?.href
      : undefined;
  }

  get secondaryButtonLinkUrl() {
    return this.product.content.secondaryButtonAction === 'navigate'
      ? this.product.content.secondaryButtonLink?.href
      : undefined;
  }

  onClicked() {
    this.clicked.emit(this.product);
  }

  onSecondaryClicked() {
    this.secondaryClicked.emit(this.product);
  }
}
