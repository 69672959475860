import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/core-jss-models';

@Component({
  selector: 'ep-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.ewo.scss'],
})
export class ImageComponent {
  @Input() rendering?: InnogyComponentRendering;
}
