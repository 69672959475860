import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/core-jss-models';
import { getFieldValue, getItemLinkFieldValue } from '@innogy/core-jss-utils';
import type { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'ep-functional-usp',
  templateUrl: './functional-usp.component.html',
  styleUrls: ['./functional-usp.component.scss'],
})
export class FunctionalUspComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;

  iconColor = 'text-dark';
  center = false;

  ngOnInit() {
    this.iconColor = getItemLinkFieldValue(
      this.rendering?.fields,
      'iconColor',
      ''
    );
    this.center = getFieldValue(this.rendering?.fields, 'center', false);
  }

  get fields() {
    return this.rendering?.fields;
  }

  get icon() {
    return this.rendering?.fields?.iconName as unknown as ComponentRendering;
  }
}
