import { Component, Input } from '@angular/core';
import type { InnogyComponentRendering } from '@innogy/core-jss-models';

@Component({
  selector: 'ep-hero-text-right',
  templateUrl: './hero-text-right.component.html',
  styleUrls: ['./hero-text-right.component.energiewonen.scss'],
})
export class HeroTextRightComponent {
  @Input() rendering?: InnogyComponentRendering | any;

  public get heroImageStyling() {
    const src = this.rendering?.fields?.backgroundImage?.value?.src;

    return src ? { 'background-image': `url(${src})` } : {};
  }
}
