import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { GenericProductData } from '@innogy/common-ui/models';
import { LocationService } from '@innogy/core-jss-seo';
import type { TextField } from '@sitecore-jss/sitecore-jss-angular';
import type { LinkFieldValue } from '@innogy/core-jss-models';
import type { StoreProductData } from '@innogy/eplus/temporary-core-modules';

import { ScGenericProductOverviewRendering } from '../models/sc-generic-offer-container.model';
import { mapScStoreProduct } from './util';

@Component({
  selector: 'wl-sc-generic-offer-container[rendering]',
  templateUrl: './sc-generic-offer-container.component.html',
})
export class ScGenericOfferContainerComponent implements OnInit {
  @Input() rendering!: ScGenericProductOverviewRendering;

  products: StoreProductData[] = [];

  constructor(private readonly location: LocationService) {}

  ngOnInit(): void {
    this.products = this.rendering.fields.Products.map((product) =>
      mapScStoreProduct(product)
    );
  }

  buttonTextField = (product: GenericProductData): TextField => {
    const overviewSubmitButtonText = this.rendering.fields.SubmitButtonText;
    return overviewSubmitButtonText.value !== ''
      ? overviewSubmitButtonText
      : { value: product.content.submitButtonText };
  };

  onProductClicked = (product: GenericProductData) => {
    const { SubmitButtonAction, SubmitButtonLink } = this.rendering.fields;
    const submitActionValue = SubmitButtonAction?.fields?.value?.value;

    // If submitButton is configured via overview for all products,
    // give this precedence over product specific submit configuration,
    // otherwise take submit configuration for each product individually
    const overviewSubmitNavigateConfigured =
      submitActionValue === 'navigate' && SubmitButtonLink.href !== '';
    const productSubmitNavigationConfigured =
      product.content.submitButtonAction === 'navigate' &&
      product.content.submitButtonLink?.href !== '';
    if (overviewSubmitNavigateConfigured || productSubmitNavigationConfigured) {
      this.location.navigateScLink(
        overviewSubmitNavigateConfigured && SubmitButtonLink.value != null
          ? SubmitButtonLink.value
          : (product.content.submitButtonLink as LinkFieldValue)
      );
    }
  };

  onProductSecondaryClicked = (product: GenericProductData) => {
    const productSecondaryNavigationConfigured =
      product.content.secondaryButtonAction === 'navigate' &&
      product.content.secondaryButtonLink?.href !== '';
    if (
      productSecondaryNavigationConfigured &&
      product.content.secondaryButtonLink != null
    ) {
      this.location.navigateScLink(product.content.secondaryButtonLink);
    }
  };
}
