import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import {
  CONFIGMODULE_CONFIG,
  TokenRefreshService,
} from '@innogy/core-config-angular';
import { CoreLoaderModule } from '@innogy/core/loader';
import { TransferHttpCacheInterceptor } from '@innogy/core/modules/common';
import { IS_SITECORE_SERVER } from '@innogy/core-jss-models';
import { EMPTY } from 'rxjs';

export function tokenServiceFactory() {
  return { refreshToken: () => EMPTY };
}

export const DEPLOY_URL_VALUE = 'dist/energiewonen/browser';
export const DEPLOY_URL_PROVIDER = {
  provide: 'DEPLOY_URL',
  useValue: DEPLOY_URL_VALUE,
};

@NgModule({
  imports: [CoreLoaderModule],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: APP_BASE_HREF, useValue: '' },
    {
      provide: CONFIGMODULE_CONFIG,
      useValue: {
        appName: 'energiewonen',
        basePath: '',
      },
    },
    {
      provide: IS_SITECORE_SERVER,
      useValue: false,
    },
    {
      provide: TokenRefreshService,
      useFactory: tokenServiceFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: TransferHttpCacheInterceptor,
    },
    DEPLOY_URL_PROVIDER,
  ],
})
export class EnergiewonenSharedModule {
  public constructor(
    @Optional()
    @SkipSelf()
    parentModule: EnergiewonenSharedModule
  ) {
    if (parentModule) {
      throw new Error('EnergiewonenSharedModule is imported more than once.');
    }
  }
}
