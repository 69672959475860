import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import type { JssNavItem } from '@innogy/core-jss-models';
import { JssRouteBuilderService } from '@innogy/core-jss-routing';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ep-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.ew.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Input()
  navigationItems?: JssNavItem[];

  @Input()
  isVertical?: boolean;

  @Input()
  language?: string;

  @Output()
  navItemClicked = new EventEmitter<void>();

  private readonly onDestroy$ = new Subject();

  constructor(
    private readonly router: Router,
    private readonly urlBuilder: JssRouteBuilderService
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.onDestroy$)
      )
      .subscribe((_) => this.navItemClicked.emit());
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  getRouteUrl(navItem: JssNavItem, language: string) {
    return this.urlBuilder.createRouteData(navItem, language);
  }

  trackByItems(_index: number, item: JssNavItem) {
    return item.path;
  }
}
